// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import SpawningLogoPng from "assets/images/spawning-logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Spawning",
    description: "Data Governance for Generative AI",
    image: SpawningLogoPng,
    route: "/",
  },
  socials: [
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/spawning_",
      altText: "Spawning on Twitter",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@spawningAI",
      altText: "Spawning on YouTube",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/Spawning-Inc",
      altText: "Spawning on GitHub",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/spawning/",
      altText: "Spawning on LinkedIn",
    },
  ],
  menus: [
    {
      name: "Creatives",
      items: [
        {
          name: "Have I Been Trained?",
          href: "https://haveibeentrained.com",
        },
        {
          name: "Browser Extension",
          href: "https://site.spawning.ai/browser-extension",
        },
        {
          name: "ai.txt",
          href: "https://site.spawning.ai/spawning-ai-txt",
        },
      ],
    },
    {
      name: "Developers",
      items: [
        {
          name: "Data Dilligence",
          route: "https://github.com/Spawning-Inc/datadiligence",
        },
        {
          name: "Spawning API",
          href: "https://api.spawning.ai/spawning-api",
        },
        {
          name: "API Docs",
          href: "https://datadiligence.readthedocs.io/en/latest/quickstart.html",
        },
      ],
    },
    {
      name: "company",
      items: [
        {
          name: "About",
          route: "https://spawning.ai",
        },
        {
          name: "Blog",
          href: "https://spawning.substack.com",
        },
      ],
    },
    {
      name: "legal",
      items: [
        {
          name: "Terms of Service",
          route: "/terms-of-service",
        },
        { name: "Privacy Policy", route: "/privacy-policy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="https://spawning.ai"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Spawning
      </MKTypography>
      .
    </MKTypography>
  ),
};
