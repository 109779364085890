import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "sections/SectionTitle";
import MKTypography from "components/MKTypography";
import { List, ListItem } from "@mui/material";
import MKBox from "components/MKBox";

const TermsOfService = () => {
  return (
    <MKBox>
      <SectionTitle title="Terms of Service" />
      <MKBox
        mt="60px"
        sx={{
          paddingRight: " 1.5rem!important",
          paddingLeft: " 1.5rem!important",
          marginRight: "auto!important",
          marginLeft: "auto!important",
          width: "100%!important",
          position: "relative",
          maxWidth: "1140px!important",
        }}
      >
        <List>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">1. Introduction </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Welcome to Kudurru. These Terms of Service ("Terms") govern your
                use of Kudurru's defensive tool designed to protect your content
                from non-consenting data scrapers.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">2. Acceptance of Terms</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                By accessing or using Kudurru, you acknowledge that you have
                read, understood, and agree to be bound by these Terms and any
                modifications that may be made to these Terms from time to time.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                3. Description of Service
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Kudurru offers a robust defense mechanism that prevents known
                web scrapers, primarily those targeting data for AI model
                training, from accessing and capturing your website content.
                Upon detection, Kudurru can block media requests or substitute
                requested media with a user-specified image, thus ensuring data
                privacy and consent.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">4. User Responsibilities</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                a. Account Security: You are responsible for maintaining the
                confidentiality of your Kudurru account credentials. b. Use of
                Service: Ensure you use Kudurru as intended, without attempts to
                reverse-engineer or misuse the service.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">5. Service Restrictions</MKTypography>
              <MKTypography
                variant="body1"
                mb={3}
                pb={1}
                pr={3}
                sx={{
                  whiteSpace: "pre",
                }}
              >
                {`Users shall not: 
                a. Decompile, disassemble, or otherwise reverse engineer Kudurru or its mechanisms. 
                b. Use Kudurru for any illegal purposes or in violation of any local, state, national, or international law. 
                c. Attempt to bypass or undermine the service's anti-scraper algorithms. 
                d. Reproduce, duplicate, copy, sell, trade, or resell Kudurru for any purpose. 
                e. Overburden or impair the functionality or disrupt the operations of the Service.`}
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">6. Intellectual Property</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                All rights, titles, and interests in and to Kudurru, including
                any software, are and will remain the exclusive property of
                Kudurru and its licensors. No rights are granted to you other
                than the limited license explicitly granted in these Terms.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                7. Data Protection and Privacy
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                By using Kudurru, you agree to the collection, transfer,
                processing, storage, and use of your data as set forth in our
                <Link
                  to="/privacy-policy"
                  style={{
                    color: "#000",
                    borderBottom: "2px solid #000",
                  }}
                >
                  {" "}
                  Privacy Policy.
                </Link>
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                8. Modifications to the Service or Terms
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Kudurru reserves the right to modify or discontinue its services
                at any time, temporarily or permanently, without prior notice.
                Additionally, we may update these Terms from time to time. Your
                continued use after such changes indicates your acceptance of
                the updated Terms.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">9. Termination</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Kudurru reserves the right to suspend or terminate your access
                if you breach any of these Terms or for any other reason at
                Kudurru's sole discretion.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                10. Liability and Disclaimers
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                The service is provided "as is." Kudurru disclaims all
                warranties and representations of any kind, whether express,
                implied, or statutory. Kudurru shall not be held liable for any
                damages arising out of or in connection with your use of the
                Service.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">11. Indemnification</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                You agree to indemnify, defend, and hold harmless Spawning Inc.
                and its affiliates, officers, directors, employees, and agents
                from any claims, liabilities, damages, losses, or expenses
                arising out of or related to your use of the API or your
                violation of these Terms.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                12. Governing Law and Jurisdiction
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                These Terms shall be governed by and construed in accordance
                with the laws of the State of Delaware, without regard to its
                conflict of law provisions. The parties agree that any legal
                action or proceeding arising out of or relating to these Terms
                shall be brought exclusively in the state or federal courts
                located in the State of Delaware, and each party hereby consents
                to the jurisdiction and venue of such courts.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">13. Contact Information</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                For any questions regarding these Terms or the Service, please
                contact us at kudurru [at] spawning [dot] ai.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">14. Changes to Terms</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Spawning Inc. reserves the right to modify these Terms at any
                time. By continuing to use the Kudurru after such modifications,
                you agree to be bound by the updated Terms.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">15. Severability</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                If any provision of these Terms is found to be invalid or
                unenforceable by a court of competent jurisdiction, the
                invalidity or unenforceability of such provision shall not
                affect the other provisions of these Terms, and all provisions
                not affected shall remain in full force and effect.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">16. Waiver</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                No waiver of any term or condition of these Terms shall be
                deemed a further or continuing waiver of such term or condition
                or any other term or condition, and Spawning Inc.'s failure to
                assert any right or provision under these Terms shall not
                constitute a waiver of such right or provision.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">17. Entire Agreement</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                These Terms, together with any other legal notices or agreements
                published by Spawning Inc. on its website or in connection with
                the API, shall constitute the entire agreement between you and
                Spawning Inc. concerning Kudurru.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">18. Assignment</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                You may not assign your rights or obligations under these Terms
                without the prior written consent of Spawning Inc.. Spawning
                Inc. may assign its rights or obligations under these Terms at
                its discretion.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">19. Force Majeure</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Spawning Inc. shall not be liable for any delay or failure in
                performance resulting from causes beyond its reasonable control,
                including, but not limited to, acts of God, war, terrorism,
                riots, embargoes, acts of civil or military authorities, fire,
                floods, accidents, strikes, or shortages of transportation
                facilities, fuel, energy, labor, or materials.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                20. Third-Party Beneficiaries
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Except as explicitly provided in these Terms, nothing in these
                Terms shall create or confer any rights or other benefits in
                favor of any third parties.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">21. Notices</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                All notices, requests, and other communications under these
                Terms must be in writing and sent to Spawning Inc. at the
                address specified in the Contact Information section (13) of
                these Terms, or any other address that Spawning, Inc. may
                provide in the future. Notices shall be deemed to have been
                given (a) when delivered personally; (b) when sent by confirmed
                email; (c) three (3) days after being sent by registered or
                certified mail, return receipt requested, postage prepaid; or
                (d) one (1) day after being sent by a nationally recognized
                overnight courier service.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                22. Relationship of the Parties
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Nothing in these Terms shall be construed as creating a
                partnership, joint venture, or agency relationship between you
                and Spawning Inc., nor shall either party have the right or
                authority to incur any liability, debt, or cost, or enter into
                any contracts or other arrangements in the name of or on behalf
                of the other party.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">23. Survival</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Sections 5 (Intellectual Property), 7 (Termination), 8
                (Disclaimer of Warranties), 9 (Limitation of Liability), 10
                (Indemnification), 11 (Governing Law), and any other provisions
                of these Terms which, by their nature, should survive
                termination, shall survive any termination or expiration of
                these Terms.
              </MKTypography>
            </MKBox>
          </ListItem>
        </List>
      </MKBox>
    </MKBox>
  );
};

export default TermsOfService;
