import React from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

const YoutubeEmbed = ({ embedId }) => (
  <MKBox component="section" pt={8} pb={6} width="100%">
    <Container>
      <MKBox
        sx={{
          position: "relative",
          paddingBottom: "56.25%",
          height: 0,
          width: "100%",

          "& iframe": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderStyle: "none",
          },
        }}
      >
        <iframe
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </MKBox>
    </Container>
  </MKBox>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
