import React, { useState } from "react";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import { useForm, Controller } from "react-hook-form";
import MKTypography from "components/MKTypography";
import { Modal } from "@mui/material";

const asana = require("asana");

const client = asana.Client.create().useAccessToken(
  process.env.REACT_APP_ASANA_ACCESS_TOKEN
);

const WORKSPACE_GID = process.env.REACT_APP_ASANA_WORKSPACE_GID;
const PROJECT_GID = process.env.REACT_APP_ASANA_KUDURRU_PROJECT_GID;
const SECTION_GID = process.env.REACT_APP_ASANA_KUDURRU_INCOMING_SECTION_GID;

const SignUpForm = () => {
  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      website: "",
    },
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { email, website } = getValues();

    try {
      const returnedTaskData = await client.tasks.createTask({
        workspace: WORKSPACE_GID,
        data: {
          name: "Kudurru Beta Sign-Ups",
          projects: PROJECT_GID,
        },
        assignee: "jordan@spawning.ai",
        name: email,
        notes: `email: ${email}  \n website: ${website}  `,
      });

      console.log("returnedTaskData", returnedTaskData);

      client.sections.addTaskForSection(SECTION_GID, {
        task: returnedTaskData.gid,
      });

      await setWasSubmitSuccessful(true);
      await reset();
      await setIsConfirmationModalOpen(true);
    } catch (error) {
      console.error("error", error.message);
      setWasSubmitSuccessful(false);
    }
  };

  if (isConfirmationModalOpen) {
    return (
      <Modal
        open={true}
        onClose={() => setIsConfirmationModalOpen(false)}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(199, 200, 203, 0.33)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          zIndex: 3000,
        }}
      >
        <MKBox
          sx={{
            backgroundColor: "#fff",
            padding: "40px",
            minWidth: "300px",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            borderRadius: "10px",
            zIndex: 9000,
          }}
        >
          <MKTypography
            variant="h5"
            // color="white"
            fontWeight="bold"
            textTransform="uppercase"
          >
            Thank you for signing up for the Kudurru beta!
          </MKTypography>
          <MKTypography variant="body1">
            We'll review your website and be in touch soon to confirm your
            enrollment.
          </MKTypography>
          <MKButton
            variant="gradient"
            color="light"
            fullWidth
            onClick={() => setIsConfirmationModalOpen(false)}
          >
            Close
          </MKButton>
        </MKBox>
      </Modal>
    );
  }

  return (
    <form>
      <MKBox
        sx={{
          border: "1px solid #fff",
          padding: "20px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              label="Email Address"
              render={({ field }) => (
                <MKInput
                  id="email"
                  name="email"
                  variant="standard"
                  type="email"
                  label="Email Address"
                  inputProps={{
                    "aria-label": "Email Address",
                  }}
                  fullWidth
                  InputLabelProps={{
                    sx: { color: "#fff" },
                  }}
                  helperText={errors?.name?.message?.toString() || ""}
                  {...field}
                />
              )}
              rules={{
                required: "Name is required",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="website"
              label="Personal Website Address"
              render={({ field }) => (
                <MKInput
                  id="website"
                  name="website"
                  variant="standard"
                  type="website"
                  label="Personal Website Address"
                  fullWidth
                  InputLabelProps={{
                    sx: { color: "#fff" },
                  }}
                  inputProps={{
                    "aria-label": "Website Address",
                  }}
                  aria-label="Personal Website Address"
                  helperText={errors?.name?.message?.toString() || ""}
                  {...field}
                />
              )}
              rules={{
                required: "Name is required",
              }}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={12} my={6} mb="18px">
          <MKButton
            type="submit"
            variant="gradient"
            color="light"
            fullWidth
            sx={{
              fontSize: "18px",
            }}
            onClick={(e) => handleFormSubmit(e)}
          >
            {wasSubmitSuccessful ? "Sign up" : "Try Again"}
          </MKButton>
        </Grid>
      </MKBox>
    </form>
  );
};

export default SignUpForm;
