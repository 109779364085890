/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import { Padding } from "@mui/icons-material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SignUpForm from "./SignUpForm";

function Testimonials({ header, stats }) {
  const formatNumber = (num) => {
    const map = [
      { suffix: "T", threshold: 1e12, decimals: 0 },
      { suffix: "B", threshold: 1e9, decimals: 0 },
      { suffix: "M", threshold: 1e6, decimals: 1 }, // Include one decimal for millions
      { suffix: "K", threshold: 1e3, decimals: 0 },
      { suffix: "", threshold: 1, decimals: 0 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted = (num / found.threshold).toFixed(found.decimals) + found.suffix;
      return formatted;
    }

    return num;
  };

  return (
    <MKBox component="section" position="relative" id="join" pt={8} pb={8}>
      <MKBox variant="gradient" bgColor="dark" width="100%" py={5}>
        <Container sx={{ maxWidth: "100% !important" }}>
          <Grid container item xs={10} sx={{ mx: "auto" }}>
            <Grid container>
              <Grid
                item
                xl={5}
                xs={12}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 0 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Grid container item justifyContent="center" xs={12} my={1}>
                  <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="bold"
                    textTransform="uppercase"
                    textAlign="center"
                  >
                    {header}
                  </MKTypography>
                </Grid>

                {stats.map(({ stat, label }, key) => (
                  <MKBox key={key}>
                    <Grid container item justifyContent="center" xs={12}>
                      <MKTypography variant="h1" color="white">
                        {formatNumber(stat)}
                      </MKTypography>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={1}>
                      <MKTypography
                        variant="body2"
                        color="white"
                        fontWeight="bold"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {label}
                      </MKTypography>
                    </Grid>
                  </MKBox>
                ))}
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={6}
                sx={{
                  px: { xs: 6, xl: 0 },
                  my: { xs: 0, xl: "auto" },
                  lineHeight: 1,
                }}
              >
                <MKBox
                  width="100%"
                  component="form"
                  method="post"
                  autocomplete="off"
                >
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKTypography
                      variant="h5"
                      color="white"
                      fontWeight="bold"
                      textTransform="uppercase"
                      textAlign="center"
                    >
                      Sign up to Join The Beta
                    </MKTypography>
                  </Grid>
                  <SignUpForm />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Testimonials;

// Setting default values for the props
Testimonials.defaultProps = {
  header: "",
  stats: [],
};

// Typechecking props
Testimonials.propTypes = {
  header: PropTypes.string,
  stats: PropTypes.arrayOf(PropTypes.object),
};
