import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React cards
import InfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import InfoBackgroundCard from "components/Cards/BackgroundCards/InfoBackgroundCard";
import ColoredBackgroundCard from "components/Cards/BackgroundCards/ColoredBackgroundCard";

function CardGridInfo({ cols, cardType, cards }) {
  return (
    <MKBox component="section" pt={6} pb={6} width="100%" id="features">
      <Container>
        <Grid container spacing={3}>
          {(() => {
            switch (cardType) {
              case "Info":
                return cards.map(({ title, description, direction }, key) => (
                  <Grid item key={key} xs={12} md={6} lg={12 / cols}>
                    <InfoCard title={title} description={description} direction={direction} />
                  </Grid>
                ));
              case "InfoBg":
                return cards.map(({ pretitle, title, image, link }, key) => (
                  <Grid item key={key} xs={12} md={6} lg={12 / cols}>
                    <InfoBackgroundCard
                      pretitle={pretitle}
                      title={title}
                      image={image}
                      link={link}
                    />
                  </Grid>
                ));
              case "ColoredBg":
                return cards.map(({ image, label, title, description, action }, key) => (
                  <Grid item key={key} xs={12} md={6} lg={12 / cols}>
                    <ColoredBackgroundCard
                      color="primary"
                      image={image}
                      label={label}
                      title={title}
                      description={description}
                      action={action}
                    />
                  </Grid>
                ));
            }
          })()}
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default values for the props of HeaderHalf
CardGridInfo.defaultProps = {
  cols: 3,
  cardType: "Info",
  cards: [],
};

// Typechecking props for the HeaderHalf
CardGridInfo.propTypes = {
  cols: PropTypes.number,
  cardType: PropTypes.string,
  cards: PropTypes.array,
};

export default CardGridInfo;
