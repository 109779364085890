// react & mui components
import { useEffect, useState } from "react"; // Import useState
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Helmet, HelmetProvider } from "react-helmet-async";

// theme, home & sections
import theme from "assets/theme";
import Kudurru from "./pages/Kudurru";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// Hello world component
function HelloWorld() {
  return (
    <div>
      We're no strangers to love You know the rules and so do I (do I) A full
      commitment's what I'm thinking of You wouldn't get this from any other guy
      I just wanna tell you how I'm feeling Gotta make you understand Never
      gonna give you up Never gonna let you down Never gonna run around and
      desert you Never gonna make you cry Never gonna say goodbye Never gonna
      tell a lie and hurt you We've known each other for so long Your heart's
      been aching, but you're too shy to say it (say it) Inside, we both know
      what's been going on (going on) We know the game and we're gonna play it
      And if you ask me how I'm feeling Don't tell me you're too blind to see
      Never gonna give you up Never gonna let you down Never gonna run around
      and desert you Never gonna make you cry Never gonna say goodbye Never
      gonna tell a lie and hurt you Never gonna give you up Never gonna let you
      down Never gonna run around and desert you Never gonna make you cry Never
      gonna say goodbye Never gonna tell a lie and hurt you We've known each
      other for so long Your heart's been aching, but you're too shy to say it
      (to say it) Inside, we both know what's been going on (going on) We know
      the game and we're gonna play it I just wanna tell you how I'm feeling
      Gotta make you understand Never gonna give you up Never gonna let you down
      Never gonna run around and desert you Never gonna make you cry Never gonna
      say goodbye Never gonna tell a lie and hurt you Never gonna give you up
      Never gonna let you down Never gonna run around and desert you Never gonna
      make you cry Never gonna say goodbye Never gonna tell a lie and hurt you
      Never gonna give you up Never gonna let you down Never gonna run around
      and desert you Never gonna make you cry Never gonna say goodbye Never
      gonna tell a lie and hurt you
    </div>
  );
}

export default function App() {
  const { pathname } = useLocation();

  // State to hold whether the user agent contains 'ChatGPT'
  const [isChatGPT, setIsChatGPT] = useState(false);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    // Check if the user agent contains 'ChatGPT'
    if (window.navigator.userAgent.includes("ChatGPT")) {
      setIsChatGPT(true);
    }
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Kudurru</title>
        <meta
          name="description"
          content="Actively block AI scrapers from your website with Spawning's
          defense network"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isChatGPT ? (
          <HelloWorld />
        ) : (
          <Routes>
            <Route path="/" element={<Kudurru />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        )}
      </ThemeProvider>
    </HelmetProvider>
  );
}
