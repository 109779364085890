import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// HelpCenter page components
import FaqCollapse from "components/FaqCollapse";

function Faq({ questions }) {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={6} width="100%">
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12}>
            {questions.map(({ question, answer }, key) => (
              <FaqCollapse
                key={key}
                title={question}
                open={collapse === key}
                onClick={() =>
                  collapse === key ? setCollapse(false) : setCollapse(key)
                }
              >
                <div dangerouslySetInnerHTML={{ __html: answer }} />
              </FaqCollapse>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default values for the props
Faq.defaultProps = {
  questions: [],
};

// Typechecking props
Faq.propTypes = {
  questions: PropTypes.array,
};

export default Faq;
