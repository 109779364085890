/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function InfoBackgroundCard({ image, icon, pretitle, title, link }) {
  return (
    <Link to={link} target="_blank">
      <Card
        sx={({
          functions: { rgba, linearGradient },
          palette: { gradients },
          borders: { borderRadius },
        }) => ({
          backgroundImage: `${linearGradient(
            rgba(gradients.primary.main, 0.8),
            rgba(gradients.primary.state, 0.8)
          )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: borderRadius.xl,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        })}
      >
        <MKBox width="100%" p={3}>
          <MKTypography variant="h3" color="white">
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </MKTypography>
        </MKBox>
        <MKBox width="100%" py={10} px={3} lineHeight={1}>
          <MKTypography
            variant="caption"
            textTransform="uppercase"
            fontWeight="bold"
            color="white"
            opacity={0.8}
          >
            {pretitle}
          </MKTypography>
          <MKTypography variant="h3" color="white">
            {title}
          </MKTypography>
        </MKBox>
      </Card>
    </Link>
  );
}

// Setting default values for the props of InfoBackgroundCard
InfoBackgroundCard.defaultProps = {
  label: "",
  link: "",
};

// Typechecking props for the InfoBackgroundCard
InfoBackgroundCard.propTypes = {
  image: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  pretitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  link: PropTypes.string,
};

export default InfoBackgroundCard;
