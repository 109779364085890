import React from "react";
import SectionTitle from "sections/SectionTitle";
import MKTypography from "components/MKTypography";
import { List, ListItem } from "@mui/material";
import MKBox from "components/MKBox";

const PrivacyPolicy = () => {
  return (
    <MKBox>
      <SectionTitle title="Privacy Policy" />
      <MKBox
        mt="60px"
        sx={{
          paddingRight: " 1.5rem!important",
          paddingLeft: " 1.5rem!important",
          marginRight: "auto!important",
          marginLeft: "auto!important",
          width: "100%!important",
          position: "relative",
          maxWidth: "1140px!important",
        }}
      >
        <List>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                Effective Date: <b>Oct 11, 2023</b>
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                Welcome to Kudurru. This Privacy Policy describes how Kudurru
                ("we," "our," or "us") collects, uses, stores, and discloses
                your personal information when you use our services. Please read
                this Privacy Policy carefully.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                1. Information We Collect
              </MKTypography>
              <MKTypography
                variant="body1"
                mb={3}
                pb={1}
                pr={3}
                sx={{
                  whiteSpace: "pre",
                }}
              >
                {`a. Automatically Collected Data: When you use our service, we automatically collect IP addresses from visitors to your website 
    to ensure the proper functionality of our tool and to prevent misuse.
b. User-Provided Data: This includes information you provide during account setup or when configuring our service, such as your 
    email address, name, company name, and any other configuration-specific details you may provide.
`}
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                2. How We Use Your Information
              </MKTypography>
              <MKTypography
                variant="body1"
                mb={3}
                pb={1}
                pr={3}
                sx={{
                  whiteSpace: "pre",
                }}
              >{`a. Service Provision: To offer, maintain, and improve Kudurru and to ensure you receive the best user experience. 
b. Security: To protect the security and integrity of our services, detect fraudulent activities, and ensure consistent and intended usage of our tool. 
c. Support: To provide customer assistance and support.`}</MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                3. Disclosure of Your Information
              </MKTypography>
              <MKTypography
                variant="body1"
                mb={3}
                pb={1}
                pr={3}
                sx={{
                  whiteSpace: "pre",
                }}
              >{`We do not sell or trade user data. We may disclose your personal information only under the following circumstances:

a. Legal Requirements: If we are required to do so by law or in response to valid requests by public authorities. 
b. Company Transactions: If Kudurru undergoes a business transition, such as a merger or acquisition, user data may be transferred.
`}</MKTypography>
            </MKBox>
          </ListItem>

          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">4. Data Retention</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                We retain your personal information for as long as is necessary
                to provide the services to you and for legal and business
                purposes.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">5. Security</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                We employ robust security measures to protect your data.
                However, no online service is entirely secure, and we cannot
                guarantee complete security.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">6. Your Rights</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                You have the right to access, correct, or delete your personal
                information. Please contact us if you wish to exercise these
                rights.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">
                7. Changes to This Privacy Policy
              </MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                We reserve the right to update or change our Privacy Policy at
                any time. We will notify you of any changes by posting the new
                Privacy Policy on this page.
              </MKTypography>
            </MKBox>
          </ListItem>
          <ListItem>
            <MKBox display="flex" flexDirection="column">
              <MKTypography variant="h4">8. Contact Us</MKTypography>
              <MKTypography variant="body1" mb={3} pb={1} pr={3}>
                For any questions or concerns regarding this Privacy Policy,
                please contact us at kudurru [at] spawning [dot] ai
              </MKTypography>
            </MKBox>
          </ListItem>
        </List>
      </MKBox>
    </MKBox>
  );
};

export default PrivacyPolicy;
