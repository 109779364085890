import FormaWoff2 from "assets/fonts/FormaDJRVariable-VF.woff2";
import SignifierWoff2 from "assets/fonts/Signifier-Regular.woff2";

// loading multiple fonts into MUI currently (8/2023) requires the fallbacks workaround
// https://github.com/mui/material-ui/issues/24966
export default {
  "@font-face": {
    fontFamily: "FormaDJRVariable",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `local('FormaDJRVariable'), local('FormaDJRVariable-VF'), url(${FormaWoff2}) format('woff2')`,
  },
  fallbacks: [
    {
      "@font-face": {
        fontFamily: "Signifier",
        fontStyle: "normal",
        fontDisplay: "swap",
        fontWeight: 400,
        src: `local('Signifer'), local('Signifier-Regular'), url(${SignifierWoff2}) format('woff2')`,
      },
    },
  ],
};
